import React from "react"
import "./style.scss"

interface ICentralImage {
  image: {
    fluid: any
    title?: string
  }
}

const CentralImage: React.FC<ICentralImage> = ({ image }) => {
  return (
    <div className="full-width-image">
      <img srcSet={image?.fluid?.images?.fallback?.srcSet} alt={image.title || ""} className="img-fluid js-parallax" />
    </div>
  )
}

export default CentralImage
