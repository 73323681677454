import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import CentralImage from "../components/central-image";
import Widget from "../components/widgets";
import QuoteBanner from "../components/quote-banner";
import InsideGuidewireExplore from "../components/ig-explore";
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import HeroMazeDefault from "../components/hero-default";
import { GatsbyImage } from "gatsby-plugin-image";


export interface IGiveback {
  title: string
  seoTitle: string
  seoDescription: string
  heroTagline: string
  heroHeading: string
  heroImage: any
  heroContent: {
    heroContent: string
  }

  centralImage?: {
    fluid: object
  }
  quoteAuthor: string
  quoteContent: any
  quoteImage: {
    fluid: any
  }

  upperWidgets?: Array<any>
}


interface IGivebackProps {
  contentfulCareersGiveback: IGiveback
}

interface ICareersGivebackContext {
  id: string
  slug: string
  locale: string
}

const InsideGuidewire: React.FC<PageProps<IGivebackProps,
  ICareersGivebackContext>> = ({ pageContext, path, data }) => {
    const locale = pageContext.locale || "en-US"
    const seoData = {
      title: data.contentfulCareersGiveback.seoTitle || data.contentfulCareersGiveback.title,
      description: data.contentfulCareersGiveback.seoDescription,
    }

    return (
      <PathContext.Provider
        value={{
          current: path,
          slug: pageContext.slug,
          locale: locale,
          activeLanguage: locale.substr(0, 2),
        }}
      >
        {/* Layout contains menus */}
        <Layout seoData={seoData}>
          <HeroMazeDefault
            subheading={data.contentfulCareersGiveback.heroTagline}
            heading={data.contentfulCareersGiveback.heroHeading}
            content={data.contentfulCareersGiveback.heroContent}
            className="jobs-hero culture-hero"
          />
          <div className="full-width-image">
          <GatsbyImage
            image={{ ...data.contentfulCareersGiveback.heroImage.fluid, aspectRatio: 21 / 9 }}
            alt={data.contentfulCareersGiveback.heroImage.title || ""}
          />
        </div>
          {data.contentfulCareersGiveback.upperWidgets && data.contentfulCareersGiveback.upperWidgets.map(widgetData => (
            <Widget {...widgetData} />
          ))}
          {data.contentfulCareersGiveback.centralImage && <CentralImage image={data.contentfulCareersGiveback.centralImage} />}
          <QuoteBanner quoteContent={data.contentfulCareersGiveback.quoteContent}
            quoteAuthor={data.contentfulCareersGiveback.quoteAuthor}
            quoteImage={data.contentfulCareersGiveback.quoteImage}
            className="givesback" />
          {data.contentfulCareersGiveback.widgetsBottom
            && data.contentfulCareersGiveback.widgetsBottom.length > 0
            && (<InsideGuidewireExplore items={data.contentfulCareersGiveback.widgetsBottom} />)}

          {data.contentfulCareersGiveback.widgets && data.contentfulCareersGiveback.widgets.map(widgetData => (
            <Widget {...widgetData} />
          ))}
        </Layout>

        {/* Linkedin, Facebook and GTM noscript */}
        <LinkedinNoscript />
        <FacebookNoscript />
        <GTMNoscript />
      </PathContext.Provider>
    )
  }

export default InsideGuidewire

export const query = graphql`
  query($id: String!) {
     contentfulCareersGiveback(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroTagline
      heroHeading
      
      heroImage {
        fluid: gatsbyImageData( width: 2048 quality: 100 formats: [AUTO, WEBP])      
        title
      }
      heroContent { 
        ...RichTextField
      }
      centralImage {
        fluid: gatsbyImageData( width: 2048 aspectRatio: 2.3 quality: 100 formats: [AUTO, WEBP])        
        title
      }
      quoteAuthor
      quoteContent {
        ...RichTextField
      }
      quoteImage {
        fixed: gatsbyImageData(layout: FIXED width: 757 quality: 100 formats: [AUTO, WEBP])         
        fluid: gatsbyImageData(quality: 100 formats: [AUTO, WEBP])
        
        title
      }
      
      upperWidgets {
        ...ComponentCTA
      }
      widgetsBottom {
        ...ComponentCard
      }
    }
  }
`
