import React from "react"
import "./style.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CTA from "../../elements/CTA"
import RichTextRenderer from "../../elements/RichTextRenderer"
import classNames from "classnames"
interface InsideGuidewireExplore {
  items: Array<InsideGuidewireExploreItem>
}

interface InsideGuidewireExploreItem {
  image: {
    fluid: any
    title?: string
  }
  title: string
  heading: string
  content: {
    content: string
  }
  links: any
}

const InsideGuidewireExploreMobile: React.FC<InsideGuidewireExplore> = ({ items }) => {
  return (
    <div className="explore">
      <div className="container-fluid">
        <div className="row">
          {items?.map((item, index) => (
            <div className="col-md-6 explore-item fade-in-up" key={index}>
              <div className="explore-image">
                {item.image && (
                  <GatsbyImage image={item.image.fluid} alt={item.image.title || ""} className="img-fluid" />
                )}
              </div>
              <div className="explore-content container">
                <span
                  className={classNames(
                    "explore-title",
                    index % 2 === 0 ? "ig-explore-link-color-1" : "ig-explore-link-color-2"
                  )}
                >
                  {item.title}
                </span>
                <h2>{item.heading}</h2>
                <RichTextRenderer json={item.content} />
                {item.links && item.links.length > 0 && (
                  <CTA
                    {...item.links[0]}
                    buttonClass={"ig-explore-cta-btn"}
                    className={index % 2 === 0 ? "ig-explore-link-1" : "ig-explore-link-2"}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const InsideGuidewireExploreDesktop: React.FC<InsideGuidewireExplore> = ({ items }) => {
  return (
    <div className="explore">
      <div className="container-fluid">
        <div className="row">
          {items?.map((item, index) => (
            <div className="col-md-6 explore-item fade-in-up" key={index}>
              <div className="explore-image">
                {item.image && (
                  <GatsbyImage image={item.image.fluid} alt={item.image.title || ""} className="img-fluid" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container explore-content-container">
        <div className="row">
          {items.map((item, index) => (
            <div className="col-md-6 explore-item fade-in-up" key={index}>
              <div className="explore-content">
                <span
                  className={classNames(
                    "explore-title",
                    index % 2 === 0 ? "ig-explore-link-color-1" : "ig-explore-link-color-2"
                  )}
                >
                  {item.title}
                </span>
                <h2>{item.heading}</h2>
                <RichTextRenderer json={item.content} />
                {item.links && item.links.length > 0 && (
                  <CTA
                    {...item.links[0]}
                    buttonClass={"ig-explore-cta-btn"}
                    className={index % 2 === 0 ? "ig-explore-link-1" : "ig-explore-link-2"}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const InsideGuidewireExplore: React.FC<InsideGuidewireExplore> = ({ items }) => {
  return (
    <>
      <div className="explore-mobile">
        <InsideGuidewireExploreMobile items={items} />
      </div>
      <div className="explore-desktop">
        <InsideGuidewireExploreDesktop items={items} />
      </div>
    </>
  )
}

export default InsideGuidewireExplore
